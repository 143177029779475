// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-conspiracies-result-animal-spying-md": () => import("./../../../src/conspiracies/result/animal-spying.md" /* webpackChunkName: "component---src-conspiracies-result-animal-spying-md" */),
  "component---src-conspiracies-result-area-51-md": () => import("./../../../src/conspiracies/result/area-51.md" /* webpackChunkName: "component---src-conspiracies-result-area-51-md" */),
  "component---src-conspiracies-result-australia-fake-md": () => import("./../../../src/conspiracies/result/australia-fake.md" /* webpackChunkName: "component---src-conspiracies-result-australia-fake-md" */),
  "component---src-conspiracies-result-chemtrails-md": () => import("./../../../src/conspiracies/result/chemtrails.md" /* webpackChunkName: "component---src-conspiracies-result-chemtrails-md" */),
  "component---src-conspiracies-result-elvis-still-alive-md": () => import("./../../../src/conspiracies/result/elvis-still-alive.md" /* webpackChunkName: "component---src-conspiracies-result-elvis-still-alive-md" */),
  "component---src-conspiracies-result-everyone-is-robot-md": () => import("./../../../src/conspiracies/result/everyone-is-robot.md" /* webpackChunkName: "component---src-conspiracies-result-everyone-is-robot-md" */),
  "component---src-conspiracies-result-false-history-md": () => import("./../../../src/conspiracies/result/false-history.md" /* webpackChunkName: "component---src-conspiracies-result-false-history-md" */),
  "component---src-conspiracies-result-flat-earth-md": () => import("./../../../src/conspiracies/result/flat-earth.md" /* webpackChunkName: "component---src-conspiracies-result-flat-earth-md" */),
  "component---src-conspiracies-result-global-warming-md": () => import("./../../../src/conspiracies/result/global-warming.md" /* webpackChunkName: "component---src-conspiracies-result-global-warming-md" */),
  "component---src-conspiracies-result-government-are-lizards-md": () => import("./../../../src/conspiracies/result/government-are-lizards.md" /* webpackChunkName: "component---src-conspiracies-result-government-are-lizards-md" */),
  "component---src-conspiracies-result-hitler-still-alive-md": () => import("./../../../src/conspiracies/result/hitler-still-alive.md" /* webpackChunkName: "component---src-conspiracies-result-hitler-still-alive-md" */),
  "component---src-conspiracies-result-hollow-earth-md": () => import("./../../../src/conspiracies/result/hollow-earth.md" /* webpackChunkName: "component---src-conspiracies-result-hollow-earth-md" */),
  "component---src-conspiracies-result-illuminati-md": () => import("./../../../src/conspiracies/result/illuminati.md" /* webpackChunkName: "component---src-conspiracies-result-illuminati-md" */),
  "component---src-conspiracies-result-moon-landing-fake-md": () => import("./../../../src/conspiracies/result/moon-landing-fake.md" /* webpackChunkName: "component---src-conspiracies-result-moon-landing-fake-md" */),
  "component---src-conspiracies-result-paul-mccartney-md": () => import("./../../../src/conspiracies/result/paul-mccartney.md" /* webpackChunkName: "component---src-conspiracies-result-paul-mccartney-md" */),
  "component---src-conspiracies-result-planet-nibiru-x-md": () => import("./../../../src/conspiracies/result/planet-nibiru-x.md" /* webpackChunkName: "component---src-conspiracies-result-planet-nibiru-x-md" */),
  "component---src-conspiracies-result-plants-not-real-md": () => import("./../../../src/conspiracies/result/plants-not-real.md" /* webpackChunkName: "component---src-conspiracies-result-plants-not-real-md" */),
  "component---src-conspiracies-result-truman-show-md": () => import("./../../../src/conspiracies/result/truman-show.md" /* webpackChunkName: "component---src-conspiracies-result-truman-show-md" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quiz-tsx": () => import("./../../../src/pages/quiz.tsx" /* webpackChunkName: "component---src-pages-quiz-tsx" */)
}

